import React from 'react'
import './../styles/index.styl'

const Page = ({ className, children }) => (
  <main className={`page ${className}`}>
      {children}
  </main>
)

export default Page
